.popup-cont {
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:1000;
    background-color: rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
}

.popup-innercont {
    position: relative;
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-innercont>span {
    position: absolute;
    right: 5px;
    top: 0px;
    padding: 5px;
    font-size: 22px;
    color: rgb(40, 167, 70);
    cursor: pointer;
    user-select: none;
}

.popup-innercont>span:hover {
    color: rgb(158, 2, 2);
}

.popup-innercont>span:active {
    color: rgb(90, 0, 0);
}