.main_cont {
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 12px;
    z-index: 10;
}

.image_input {
    display: none;
}

.banner_image {
    position: relative;
}

.label_banner_input {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.label_banner_input>.label_icon {
    font-size: 40px;
    color: rgba(255, 255, 255, 0.74);
}

.label_banner_input:active>.label_icon {
    color: rgb(158, 2, 2);
}

.profile_bio_cont {
    width: 180px;
    margin-top: -40px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    align-items: center;
    height: max-content;
}

.profile_info {
    margin: 0px;
    min-width: unset;
}

.profile_pic {
    margin: 0px;
    z-index: 10;
    position: relative;
}

.profile_pic img {
    border: none;
}

.label_profile_input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.label_profile_input>.label_icon {
    font-size: 28px;
    color: rgba(255, 255, 255, 0.74);
}

.label_profile_input:active>.label_icon {
    color: rgb(158, 2, 2);
}

.profile_handle_info {
    text-align: center;
    margin-right: 0px;
    margin-top: 10px;
}

.personal_details_cont {
    flex: 1;
    margin-top: -40px;
    padding: 15px;
    padding-top: 7.5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.detail_category {
    width: 100%;
    padding: 10px 0px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.edit_profile_form,
.more_info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.edit_profile_form {
    padding: 0px 5px;
}

.edit_profile_form>input {
    flex: 0.45;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 15px;
    margin-top: 18px;
    color: rgb(0, 0, 0, 0.95);
    outline: none;
    background: none;
}

.edit_profile_form>input:focus {
    border-color: rgb(40, 167, 70);
}

.ageChoices {
    margin-top: 15px;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.custom_checkbox_cont {
    display: flex;
    width: 130px;
    margin: 10px 0px;
}

.checkbox_cont {
    position: relative;
    margin-right: 30px;
}

.checkbox {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.custom_checkbox {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    border: 2px solid rgba(8, 8, 8, 0.45);
    box-sizing: border-box;
    cursor: pointer;
}

.checkbox:checked~.custom_checkbox {
    border: 4px solid rgb(40, 167, 70);
}

.checkbox_text {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.75);
}

.selectDropDown {
    width: 45%;
}

.submit_btn_span {
    margin: 10px 0px;
    margin-top: 20px;
    padding: 10px 0px;
}

.submit_btn {
    align-self: flex-start;
    width: 180px;
    padding: 10px 0px;
    border-radius: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: rgb(40, 167, 70);
    color: white;
    border: none;
}

.submit_btn:hover {
    background-color: rgb(2, 124, 30);
}

.submit_btn:active {
    background-color: rgb(158, 2, 2);
}

@media screen and (max-width: 700px) {
    .edit_profile_form {
        flex-direction: column;
        padding: 0px 10px;
    }
    .more_info {
        flex-direction: column;
    }
    .selectDropDown {
        width: 100%;
    }
}

@media screen and (max-width:550px) {
    .main_cont {
        flex-direction: column;
    }
    .profile_bio_cont {
        margin-top: 20px;
        width: 100%;
    }
    .personal_details_cont {
        margin-top: 20px;
        border: none;
        padding: 0px;
    }
    .submit_btn {
        width: 150px;
        font-size: 13px;
    }
}