.index_body {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
}

@media (max-width: 992px) {
  .index_body {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 55px;
  }
}
