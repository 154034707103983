.comments {
  border: 1px solid rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 20px;
  border-radius: 25px;
  margin-top: 10px;
  justify-content: center;
  max-width: 400px;
}
.rightSide_comments {
  margin-left: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 25px;
  padding: 20px;
  padding-bottom: 0px;
}
.rightSide_comments > p {
  margin-top: -10px;
  font-size: 12px;
}
.posted_comments {
  font-size: 14px;
}

.bottom_rightSide {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 578px) {
  .comments {
    flex-direction: column;
    align-items: center;
  }
}
