.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(40, 167, 70);
  cursor: pointer;
  font-size: 14px;
}
