.feed {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  width: 95%;
}

.feed_header {
  display: flex;
}

.feed_header_left > a > h5 {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.feed_header_left > p {
  margin-top: -10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 500;
}

.feed_heder_left_bottom {
  margin-top: -10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  display: flex;
  gap: 10px;
}

.feed_body > .img {
  width: 100%;

  border-radius: 10px;
}

.feed_footer {
  color: gray;
  display: flex;
  justify-content: space-evenly;
}
