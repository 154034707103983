.profile_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.profile_image_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
}

.banner_image {
  width: 100%;
  position: relative;
}

.banner_image::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  filter: blur(2px);
  background: linear-gradient(
    90deg,
    rgba(63, 45, 45, 0.65),
    rgba(40, 167, 70, 0.65),
    rgba(158, 2, 2, 0.65)
  );
}

.banner_image img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  aspect-ratio: 5/1;
  object-fit: cover;
}

.profile_image_cont > span {
  width: 100%;
  display: flex;
  padding: 10px 35px;
  justify-content: flex-start;
  position: relative;
}

@media screen and (max-width: 600px) {
  .banner_image img {
    aspect-ratio: 4/1;
  }
}

@media screen and (max-width: 400px) {
  .banner_image img {
    aspect-ratio: 3/1;
  }
}

.profile_pic {
  margin-top: -55px;
  margin-right: 25px;
  margin-left: 30px;
  z-index: 1234;
  padding-right: 1%;
}

.profile_pic img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.35);
  object-fit: cover;
}

.profile_info {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.profile_handle_info {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.profile_name {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.95);
}

.profile_username {
  font-size: 13px;
  color: rgb(40, 167, 70);
}

@media screen and (max-width: 820px) {
  .profile_image_cont > span {
    flex-direction: column;
  }
  .profile_btn_cont {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .profile_info {
    min-width: 75%;
    margin-top: 10px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  .profile_pic {
    margin-left: 0px;
    margin-top: -40px;
  }
  .profile_pic img {
    height: 75px;
    width: 75px;
  }
  .profile_info {
    margin-top: 15px;
    margin-left: 0px;
  }
  .profile_handle_info {
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .profile_image_cont > span {
    padding: 10px;
  }
  .profile_info {
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: center;
  }
  .profile_activity_info {
    margin-top: 10px;
  }
  .profile_activity_figures {
    font-size: 18px;
  }
  .profile_activity_category {
    font-size: 13px;
  }
}

.profile_activity_info {
  display: flex;
}

.profile_activity_info > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 15px;
}

.profile_activity_figures {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}

.profile_activity_category {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.55);
}

.profile_btn_cont {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.follow_btn {
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: rgb(40, 167, 70);
  color: white;
  border: none;
}

.follow_btn:hover {
  background-color: rgb(2, 124, 30);
}

.follow_btn:active {
  background-color: rgb(158, 2, 2);
}

.edit_profile_btn {
  padding: 8px 22px;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: none;
  color: rgb(40, 167, 70);
  border: 1px solid rgb(40, 167, 70);
  outline: none;
  box-sizing: border-box;
}

.edit_profile_btn:hover {
  color: rgb(2, 124, 30);
  border-color: rgb(2, 124, 30);
}

.edit_profile_btn:active {
  color: #fff;
  border-color: rgb(158, 2, 2);
  background-color: rgb(158, 2, 2);
}

@media screen and (max-width: 450px) {
  .follow_btn {
    padding: 8px 20px;
    font-size: 12px;
  }
  .edit_profile_btn {
    padding: 6px 18px;
    font-size: 12px;
  }
  .profile_activity_info > span {
    margin: 0px 12px;
  }
}

.nav_links_cont {
  padding: 0px 20px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.nav_links_cont > button {
  padding: 15px 35px;
  margin: 0px 12px;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.55);
  letter-spacing: 0.75px;
  background: none;
  outline: none;
  border: none;
}

.nav_links_cont > button:hover {
  color: rgb(40, 167, 70);
}

.nav_links_cont > button:active {
  color: rgb(158, 2, 2);
}

.active_tab {
  font-weight: 600;
  color: rgb(40, 167, 70) !important;
  border-bottom: 2px solid rgb(40, 167, 70) !important;
}

@media screen and (max-width: 450px) {
  .nav_links_cont > button {
    font-size: 15px;
    padding: 10px 20px;
    margin: 0px 7px;
    padding-top: 5px;
  }
}

.view_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
}
