.rightside {
  flex: 0.2;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.top_other_leaders {
  border-radius: 10px 50px;
  background-color: white;
  padding: 10px;
  width: 20vw;
  /* position: sticky;
  top: 100px; */
  /* position: absolute; */
}
.top_other_leaders > h4 {
  font-size: 18px;
  font-weight: 800;
  background-image: url("../images/kenya-flag.jpg");
  background-position: center;
  background-size: contain;
  color: transparent;
  -webkit-background-clip: text;
  text-align: center;
}

@media (max-width: 992px) {
  .rightside {
    display: none;
  }
}

.rightside_twitter_embed {
  background-color: white;
  padding: 10px;
  position: sticky;
  /* z-index: 1030; */
  top: 100px;
  max-width: 250px;
  height: 65vh;
  overflow-y: scroll;
  /* max-height: 40%; */
}
/* @media (max-width: 992px) {
  .rightside_twitter_embed {
    position: absolute;

    max-height: 200px;
    bottom: 0;
    right: 0;
  }
} */
