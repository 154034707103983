.login {
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 500px) {
  .login {
    width: 240px;
    padding: 5px;
  }
}

.login > h2 {
  margin-bottom: 15px;
  font-size: large;
  color: rgb(0, 0, 0, 0.8);
}

.login > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login > form > input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 16px;
  margin-top: 18px;
  color: rgb(0, 0, 0, 0.95);
  outline: none;
  background: none;
}

.login > form > input:focus {
  border-color: rgb(40, 167, 70);
}

.login > form > button {
  width: 180px;
  padding: 10px 0px;
  margin-top: 25px;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #28a746;
  color: white;
  border: none;
}

.login > form > button:hover {
  background-color: rgb(2, 124, 30);
}

.login > form > button:active {
  background-color: rgb(158, 2, 2);
}

.forgot-pass {
  text-decoration: none;
  width: 100%;
  margin-top: 15px;
  padding: 5px 0px;
  font-size: 14px;
  text-align: left;
  color: rgb(40, 167, 70);
  cursor: pointer;
  user-select: none;
}

.forgot-pass:hover {
  color: rgb(2, 124, 30);
}

.forgot-pass:active {
  color: rgb(158, 2, 2);
}

form ~ span {
  margin: 14px 0px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.55);
  text-align: center;
}

.google-button {
  width: 180px;
  padding: 10px 0px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.75);
  font-size: 13px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
}

.google-button:hover {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
}

.google-button:active {
  background-color: rgb(158, 2, 2);
  color: #fff;
}
