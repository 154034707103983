.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 25px 0px 0px 25px;
  padding: 10px;
  overflow: scroll;
  /* background-color: white; */
}
.input_top {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}
.cancel_btn {
  color: rgb(40, 167, 70);
}
.comment_top {
  background-color: white;
  min-width: 500px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 25px 25px 0px 0px;
}
.comment_input {
  margin: 10px;
}
.input_bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.comment_input input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  padding: 10px;
  flex: 0.7;
}
.comment_input button {
  flex: 0.1;
  font-size: 12px;
  background-color: rgb(40, 167, 70);
  color: white;
  font-weight: 600;
  border: 1px;
  height: 30px;
}

.subtitle_comment {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.comments_body {
  background-color: white;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 578px) {
  .comment_top,
  .comments_body {
    min-width: 350px;
    max-width: 20px;
  }
  .input_bottom {
    flex-direction: column;
  }
  .input_bottom > button {
    margin-top: 10px;
  }
}
@media (max-width: 402px) {
  .comment_top,
  .comments_body {
    min-width: 270px;
    max-width: 20px;
  }
}
